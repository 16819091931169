@import "pwc-variables";
@import "pwc-mixins";

.custom-table.ui.table {
  margin: 0;
  // table-layout: fixed;
  border: none;
  border-radius: 0;
  box-shadow: none !important;

  @include generic-shadow();

  &.padded-table {
    th,
    td {
      padding-top: 1.25rem !important;
      padding-bottom: 1.25rem !important;
    }

    tbody td:last-child {
      // display: flex;
      // justify-content: flex-start !important;
    }
  }

  tr td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .dropdown {
    overflow: initial !important;
  }

  &.selectable {
    tbody tr {
      outline: none;

      &:hover {
        background: #f5f5f5 !important;

        td {
          color: $rose;
        }
      }

      &:focus,
      &:active {
        background: lighten($light-silver, 20%) !important;

        td {
          color: $rose;
        }
      }
    }

    .noahover {
      &:hover {
        background: transparent !important;
      }
    }
  }

  .ui.input {
    padding: 0;

    input {
      padding-left: 0;
      border: none;
      border-bottom: 2px solid $silver !important;

      &:focus {
        border-bottom: 2px solid $rose !important;
      }
    }
  }

  .ui.input .input-group {
    &:focus-within {
      .input-group-append button,
      .form-control {
        border: none !important;
        border-bottom: 2px solid $rose !important;
      }
    }
  }

  .ui.input .input-group .input-group-append {
    button {
      display: flex;
      width: 2rem;
      padding: 0;
      border: none !important;
      border-bottom: 2px solid $silver !important;

      &:focus,
      &:active {
        border: none !important;
        border-bottom: 2px solid $rose !important;
      }

      svg-icon {
        margin: auto;
      }
    }
  }

  .ui.segment {
    padding: 0;
    box-shadow: none;

    .ng-select .ng-dropdown-panel.ng-select-bottom {
      margin-top: -2px;
    }

    .ng-select.ng-select-single .ng-select-container,
    .ui.selection.dropdown {
      min-width: 14rem;
      min-height: 3.1rem;
      padding-left: 0;
      border: none;
      border-bottom: 2px solid $silver !important;

      &:focus {
        border-bottom: 2px solid $rose !important;
      }

      .item {
        &:hover {
          color: $white;
          background: $rose;
        }
      }

      &:focus,
      &:active {
        border: none !important;
        border-bottom: 2px solid $rose !important;
        box-shadow: 0 0;
      }

      ::ng-deep.menu {
        margin-top: 0.5rem;
        border: none !important;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: 0 0;
      }
    }
  }

  th,
  td {
    padding-right: 2rem !important;
    //padding: 1rem 2rem !important;
    padding-left: 2rem !important;
    transition: none;
  }

  thead th {
    color: $white;
    background-color: $seventy-black;
    border-radius: 0 !important;
  }

  tbody tr {
    border-bottom: 1px solid $silver;
  }

  tbody td:last-child {
    // display: flex;
    // justify-content: flex-end;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  tfoot th {
    padding-right: 0 !important;
    background: $content-background;
    border: none;

    .ui.right.floated.menu {
      margin-top: 1rem;
      background: transparent;
      border-radius: 0;
      box-shadow: none;

      .icon {
        width: $small-5x;
        margin: 0;
      }
    }

    .description {
      display: flex;
      align-items: center;
      padding-right: 2rem;
      color: $ninety-black;
      font-weight: bold;
      border: none !important;

      &:hover {
        background: transparent !important;
      }
    }
  }

  .ui.basic.button {
    color: $rose !important;
  }

  .icon {
    box-shadow: none !important;

    &:active {
      background: transparent !important;
    }
  }
}

.ui.basic.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: $ninety-black;
  font-weight: bold;
  background: transparent;
  border-radius: 0;

  &:not(.btn-icon) img {
    width: $icon-regular;
    margin: 0 1rem 0 0;
  }

  .left {
    width: 1rem;
    margin-right: -0.5rem;
    margin-left: 5rem;
  }
}

.ui.segment {
  @include generic-shadow();

  .ui.list {
    box-shadow: none;
  }
}

.ui.segments {
  display: flex;
  flex-direction: row;
  margin: 0;
  border: none;
  box-shadow: 0 0;

  .ui.segment {
    background: transparent;
    border: none;

    @include generic-shadow();

    .ui.list {
      box-shadow: none;
    }

    .ui.selection.dropdown {
      display: flex;
      align-items: center;

      // magic number
      height: 3.1rem;
      border-radius: 0;

      .item {
        &:hover {
          color: $white;
          background: $rose;
        }
      }

      &:focus {
        border: 1px solid $rose;
      }

      ::ng-deep.menu {
        border: 1px solid $rose;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.ui.input {
  padding-right: 2rem;
  padding-bottom: 1rem;

  input {
    color: $ninety-black;
    border-radius: 0;

    &:focus,
    &.active {
      border: 1px solid $silver;
    }
  }
}

.modal {
  border-radius: 0;

  .modal-header {
    border: none;
  }

  // .close {
  //   top: 0;
  //   right: 0;
  //   color: $silver;
  // }

  .modal-body {
    .option {
      margin-top: 1rem;
      padding: 2rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    p {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: $white;
    border: none;

    .transparent {
      padding-left: 0.5rem;
      color: $rose !important;
      text-decoration: underline;
      background: transparent;
      border: none;
      box-shadow: 0 0;
    }

    .button-group {
      .rose {
        padding: 1rem 4rem 1rem 4rem;
        color: $white !important;
        background: $rose !important;
      }

      .silver {
        color: $silver !important;
        background: transparent !important;
        border: none;
        box-shadow: 0 0;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.ui.list {
  background: $white;

  @include generic-shadow();

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem !important;
    text-align: justify;

    .name {
      min-width: 30%;
      max-width: 30%;
      color: $silver;
    }

    .v {
      padding-left: 2rem;
      color: $ninety-black;

      .ui.input {
        padding: 0 !important;

        input {
          width: 30rem;
        }
      }

      ::ng-deep.changes {
        display: flex;
        flex-direction: column;

        .align {
          text-align: justify;
        }

        .change {
          display: block;

          .newValue {
            margin-left: 0.5rem;
            background: #baffc0;
          }

          .oldValue {
            margin-right: 0.5rem;
            background: #ffc3c8;
          }
        }
      }
    }

    .v2 {
      color: $ninety-black;
    }

    .v2::selection {
      border: 1px solid $rose !important;
    }

    .edit {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
      margin-left: 2rem;
      // line-height: 1.7;
      border: 1px solid #ccc !important;
    }
  }

  .edit {
    padding-top: 0.325rem !important;
    padding-bottom: 0.325rem !important;
  }
}

.ui.loader {
  // TODO
}
