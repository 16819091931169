// PWC Brand colours 2019
// Primary colours (theme 1 for light backgrounds)
$pwc-orange: #d04a02;
$pwc-tangerine: #eb8c00;
$pwc-yellow: #ffb600;
$pwc-grey: #7d7d7d;
$pwc-light-grey: #dedede;
$pwc-dark-blue: #295477;
$pwc-teal: #299d8f;

// Secondary colours (theme 1 for light backgrounds)
$pwc-blue: #4577c9;
$pwc-cyan: #00c6f7;
$pwc-turquoise: #3dd5b0;
$pwc-rose: #d93954; // #db536a;
$pwc-rose-light: #f8dde1;

// Primary colours (theme 2 for light backgrounds)
$pwc-red: #e0301e;
$pwc-dark-grey: #2d2d2d;
$pwc-medium-blue: #3f88c5;

// Other colours
$pwc-white: #fff;

// Variations

$pwc-orange-1: #e45c2b;
$pwc-orange-2: #fb7c4d;
$pwc-orange-3: #fdab8d;
$pwc-orange-4: #fedacc;

$pwc-tangerine-1: #efa333;
$pwc-tangerine-2: #f3ba66;
$pwc-tangerine-3: #f7d199;
$pwc-tangerine-4: #fbe8cc;

$pwc-yellow-1: #ffbd26;
$pwc-yellow-2: #ffc94c;
$pwc-yellow-3: #ffdc8c;
$pwc-yellow-4: #fff0cc;
// ...

// Status Color
$pwc-status-red: #c52a1a;
$pwc-status-green: #22992e;
$pwc-status-yellow: #ffbf1f;

// Grey colours
$pwc-grey-2: #252525;
$pwc-grey-3: #3b3b3b;
$pwc-grey-5: #535353;
$pwc-grey-6: #696969;
$pwc-grey-8: #818181;
$pwc-grey-9: #979797;
$pwc-grey-a: #afafaf;
$pwc-grey-b: #bababa;
$pwc-grey-c: #c5c5c5;
$pwc-grey-d: #ddd;
$pwc-grey-f: #f3f3f3;

$pwc-black-alt: $pwc-grey-3;
