// stylelint-disable scss/dollar-variable-colon-space-after

// Modals

// Padding applied to the modal body
$modal-inner-padding: p2r(24px);

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: p2r(10px);

$modal-dialog-margin: $modal-inner-padding;
// $modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: (24 / 20) * 1em;

// $modal-content-color: null !default;
// $modal-content-bg: $white !default;
// $modal-content-border-color: rgba($black, 0.2) !default;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;
// $modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
// $modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($black, 0.5) !default;
// $modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.5) !default;

$modal-backdrop-bg: $pwc-grey-2;
$modal-backdrop-opacity: 0.6;
// $modal-header-border-color: $border-color !default;
// $modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: 0;
$modal-footer-border-width: 1px;
$modal-header-padding-y: p2r(24px);
$modal-header-padding-x: p2r(24px);
// $modal-header-padding: $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

// $modal-xl: 1140px !default;
// $modal-lg: 800px !default;
// $modal-md: 500px !default;
// $modal-sm: 300px !default;

// $modal-fade-transform: translate(0, -50px) !default;
// $modal-show-transform: none !default;
// $modal-transition: transform 0.3s ease-out !default;
// $modal-scale-transform: scale(1.02) !default;
