// Positioning

@mixin position--absolute($margin: 0) {
  position: absolute;
  top: $margin;
  right: $margin;
  bottom: $margin;
  left: $margin;
}

@mixin position--absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

@mixin position--absolute-y-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin position--absolute-x-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
