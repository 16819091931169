.form-control {
  padding-bottom: $input-padding-y + p2r(1px);

  &:disabled,
  &[readonly] {
    color: $g-input-disabled-color;
    border-color: $g-input-disabled-border-color;

    &::placeholder {
      color: $g-input-disabled-color;
    }
  }
}

small.form-text {
  display: block;
  width: 100%;
  line-height: (20 / 12) * 1em;
}

.invalid-feedback {
  font-size: $font-size-sm;
}
