// stylelint-disable scss/dollar-variable-colon-space-after

// Form validation

$form-feedback-margin-top: p2r(10px);
$form-feedback-font-size: $g-font-size-base;
// $form-feedback-valid-color: theme-color("success") !default;
// $form-feedback-invalid-color: theme-color("danger") !default;

// $form-feedback-icon-valid-color: $form-feedback-valid-color !default;
// $form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http:// www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
// $form-feedback-icon-invalid-color: $form-feedback-invalid-color !default;
// $form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http:// www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;

// $form-validation-states: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
// $form-validation-states: map-merge(
//   (
//     "valid": (
//       "color": $form-feedback-valid-color,
//       "icon": $form-feedback-icon-valid
//     ),
//     "invalid": (
//       "color": $form-feedback-invalid-color,
//       "icon": $form-feedback-icon-invalid
//     ),
//   ),
//   $form-validation-states
// );
