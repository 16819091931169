@mixin text--heading {
  font-weight: 700;
  font-size: p2r(32px);
  font-family: $g-font-family--secondary;
  line-height: (38 / 32) * 1em;
}

@mixin text--heading-light {
  font-weight: 400;
  font-size: p2r(18px);
  font-family: $g-font-family--primary;
  line-height: (22 / 18) * 1em;
}

@mixin text--subheading {
  font-weight: 400;
  font-size: p2r(16px);
  font-family: $g-font-family--primary;
  line-height: (18 / 16) * 1em;
}

@mixin text--section-heading {
  font-weight: 500;
  font-size: p2r(16px);
  font-family: $g-font-family--primary;
  line-height: (20 / 16) * 1em;
}

@mixin text--progress {
  font-weight: 500;
  font-size: p2r(16px);
  font-family: $g-font-family--primary;
  line-height: (22 / 16) * 1em;
}

@mixin text--label {
  font-weight: 700;
  font-size: p2r(16px);
  font-family: $g-font-family--primary;
  line-height: (20 / 16) * 1em;
}

@mixin text--mini-card-header {
  font-weight: bold;
  font-size: p2r(16px);
  font-family: $g-font-family--primary;
  line-height: (18 / 16) * 1em;
}

@mixin text--mini-card-body {
  font-weight: 400;
  font-size: p2r(14px);
  font-family: $g-font-family--primary;
  line-height: (24 / 14) * 1em;
}

@mixin text--popover {
  font-weight: 400;
  font-size: p2r(14px);
  font-family: $g-font-family--primary;
  line-height: (16 / 14) * 1em;
}

@mixin text--table-header {
  font-weight: 700;
  font-size: p2r(15px);
  font-family: $g-font-family--primary;
  line-height: (18 / 15) * 1em;
}

@mixin text--modal-title {
  font-weight: 700;
  font-size: p2r(20px);
  font-family: $g-font-family--primary;
  line-height: (24 / 20) * 1em;
}

@mixin text--modal-body {
  font-weight: 400;
  font-size: p2r(16px);
  font-family: $g-font-family--primary;
  line-height: (20 / 16) * 1em;
}
