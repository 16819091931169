// stylelint-disable scss/dollar-variable-colon-space-after

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-border-width: 1px;

$input-btn-padding-y: p2r(16px - $input-btn-border-width);
$input-btn-padding-x: p2r(30px - $input-btn-border-width);
// $input-btn-font-family: null !default;
$input-btn-font-size: p2r(14px);
$input-btn-line-height: (17 / 14);

$input-btn-focus-width: 0;
// $input-btn-focus-color: rgba($component-active-bg, 0.25) !default;
$input-btn-focus-box-shadow: $g-input-focus-box-shadow;

$input-btn-padding-y-sm: p2r(10px - $input-btn-border-width);
$input-btn-padding-x-sm: p2r(14px - $input-btn-border-width);
$input-btn-font-size-sm: p2r(13px);
$input-btn-line-height-sm: (16 / 13);

// $input-btn-padding-y-lg: 0.5rem !default;
// $input-btn-padding-x-lg: 1rem !default;
// $input-btn-font-size-lg: $font-size-lg !default;
// $input-btn-line-height-lg: $line-height-lg !default;

// $input-btn-border-width: $border-width !default;
