// stylelint-disable scss/dollar-variable-colon-space-after

// Popovers

$popover-font-size: $font-size-base;
$popover-bg: #000;
// $popover-max-width: 276px !default;
// $popover-border-width:$border-width !default;
// $popover-border-color:rgba($black, .2) !default;
// $popover-border-radius: $border-radius-lg !default;
// $popover-inner-border-radius: subtract($popover-border-radius, $popover-border-width) !default;
// $popover-box-shadow:0 .25rem .5rem rgba($black, .2) !default;

// $popover-header-bg: darken($popover-bg, 3%) !default;
// $popover-header-color:$headings-color !default;
// $popover-header-padding-y:.5rem !default;
// $popover-header-padding-x:.75rem !default;

$popover-body-color: $pwc-white;
// $popover-body-padding-y:$popover-header-padding-y !default;
// $popover-body-padding-x:$popover-header-padding-x !default;

// $popover-arrow-width: 1rem !default;
// $popover-arrow-height:.5rem !default;
// $popover-arrow-color: $popover-bg !default;

// $popover-arrow-outer-color: fade-in($popover-border-color, .05) !default;
