// date picker
ngb-datepicker {
  &-navigation-select > .custom-select {
    font-size: 1rem !important;
  }

  .ngb-dp-weekday {
    color: $primary;
  }

  [ngbDatepickerDayView] {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    border-radius: 0;

    @include hover-focus() {
      color: $pwc-white;
      background-color: $primary;
      border-color: $primary;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        color: $pwc-white;
        background-color: $primary;
        border-color: $primary;
      }
    }
  }

  .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday {
    width: 3rem;
    height: 3rem;
  }

  .ngb-dp-week-number,
  .ngb-dp-weekday {
    font-style: normal;
    line-height: 3rem;
  }
}

// type ahead
ngb-typeahead-window {
  .dropdown-item {
    padding: p2r(12px) p2r(16px);

    &:not(:last-child) {
      border-bottom: 1px solid rgba($pwc-grey-2, 0.1);
    }
  }
}

.typehead-container {
  position: relative;
  display: flex;

  ngb-typeahead-window {
    width: auto;
    min-width: 100%;

    .dropdown-item {
      white-space: normal;
    }
  }
}
