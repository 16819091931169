// stylelint-disable scss/dollar-variable-colon-space-after

// Cards

$card-spacer-y: p2r(20px);
$card-spacer-x: p2r(20px);
// $card-border-width: $border-width !default;
// $card-border-radius: $border-radius !default;
$card-border-color: $pwc-grey-d;
// $card-inner-border-radius: subtract($card-border-radius, $card-border-width) !default;
// $card-cap-bg: rgba($black, 0.03) !default;
// $card-cap-color: null !default;
// $card-height: null !default;
// $card-color: null !default;
// $card-bg: $white !default;

// $card-img-overlay-padding: 1.25rem !default;

// $card-group-margin: $grid-gutter-width / 2 !default;
// $card-deck-margin: $card-group-margin !default;

// $card-columns-count: 3 !default;
// $card-columns-gap: 1.25rem !default;
// $card-columns-margin: $card-spacer-y !default;
