// stylelint-disable scss/dollar-variable-colon-space-after

// Forms

$label-margin-bottom: $g-label-margin-bottom;

$input-padding-y: $g-input-padding-y;
$input-padding-x: $g-input-padding-x;
// $input-font-family: $input-btn-font-family !default;
$input-font-size: $g-font-size-base;
$input-font-weight: $g-font-weight-base;
$input-line-height: $g-line-height-base;

// $input-padding-y-sm: $input-btn-padding-y-sm !default;
// $input-padding-x-sm: $input-btn-padding-x-sm !default;
// $input-font-size-sm: $input-btn-font-size-sm !default;
// $input-line-height-sm: $input-btn-line-height-sm !default;

// $input-padding-y-lg: $input-btn-padding-y-lg !default;
// $input-padding-x-lg: $input-btn-padding-x-lg !default;
// $input-font-size-lg: $input-btn-font-size-lg !default;
// $input-line-height-lg: $input-btn-line-height-lg !default;

$input-bg: $g-input-bg;
$input-disabled-bg: $g-input-disabled-bg;

$input-color: $g-input-color;
$input-border-color: $g-input-border-color;
$input-border-width: $g-input-border-width;
$input-box-shadow: $g-input-box-shadow;

// $input-border-radius: $border-radius !default;
// $input-border-radius-lg: $border-radius-lg !default;
// $input-border-radius-sm: $border-radius-sm !default;

// $input-focus-bg: $input-bg !default;
$input-focus-border-color: $g-input-focus-border-color;
// $input-focus-color: $input-color !default;
// $input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $g-input-focus-box-shadow;

$input-placeholder-color: $g-input-placeholder-color;
// $input-plaintext-color: $body-color !default;

// $input-height-border: $input-border-width * 2 !default;

// $input-height-inner: add($input-line-height * 1em, $input-padding-y * 2) !default;
// $input-height-inner-half: add($input-line-height * 0.5em, $input-padding-y) !default;
// $input-height-inner-quarter: add($input-line-height * 0.25em, $input-padding-y / 2) !default;

$input-height: $g-input-height;
// $input-height-sm: add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
// $input-height-lg: add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

// $input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

// $form-text-margin-top: 0.25rem !default;

// $form-check-input-gutter: 1.25rem !default;
// $form-check-input-margin-y: 0.3rem !default;
// $form-check-input-margin-x: 0.25rem !default;

// $form-check-inline-margin-x: 0.75rem !default;
// $form-check-inline-input-margin-x: 0.3125rem !default;

// $form-grid-gutter-width: 10px !default;
// $form-group-margin-bottom: 1rem !default;

// $input-group-addon-color: $input-color !default;
// $input-group-addon-bg: $gray-200 !default;
// $input-group-addon-border-color: $input-border-color !default;

// $custom-forms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

// $custom-control-gutter: 0.5rem !default;
// $custom-control-spacer-x: 1rem !default;
// $custom-control-cursor: null !default;

$custom-control-indicator-size: p2r(16px);
// $custom-control-indicator-bg: $input-bg !default;

$custom-control-indicator-bg-size: 62.5% 62.5%; // 10px
// $custom-control-indicator-box-shadow: $input-box-shadow !default;
// $custom-control-indicator-border-color: $gray-500 !default;
// $custom-control-indicator-border-width: $input-border-width !default;

// $custom-control-label-color: null !default;

// $custom-control-indicator-disabled-bg: $input-disabled-bg !default;
// $custom-control-label-disabled-color: $gray-600 !default;

// $custom-control-indicator-checked-color: $component-active-color !default;
// $custom-control-indicator-checked-bg: $component-active-bg !default;
// $custom-control-indicator-checked-disabled-bg: rgba(theme-color("primary"), 0.5) !default;
// $custom-control-indicator-checked-box-shadow: none !default;
// $custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg !default;

// $custom-control-indicator-focus-box-shadow: $input-focus-box-shadow !default;
// $custom-control-indicator-focus-border-color: $input-focus-border-color !default;

// $custom-control-indicator-active-color: $component-active-color !default;
// $custom-control-indicator-active-bg: lighten($component-active-bg, 35%) !default;
// $custom-control-indicator-active-box-shadow: none !default;
// $custom-control-indicator-active-border-color: $custom-control-indicator-active-bg !default;

// $custom-checkbox-indicator-border-radius: $border-radius !default;
// $custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http:// www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>") !default;

// $custom-checkbox-indicator-indeterminate-bg: $component-active-bg !default;
// $custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
// $custom-checkbox-indicator-icon-indeterminate: url("data:image/svg+xml,<svg xmlns='http:// www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/></svg>") !default;
// $custom-checkbox-indicator-indeterminate-box-shadow: none !default;
// $custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg !default;

// $custom-radio-indicator-border-radius: 50% !default;
// $custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http:// www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>") !default;

// $custom-switch-width: $custom-control-indicator-size * 1.75 !default;
// $custom-switch-indicator-border-radius: $custom-control-indicator-size / 2 !default;
// $custom-switch-indicator-size: subtract($custom-control-indicator-size, $custom-control-indicator-border-width * 4) !default;

// $custom-select-padding-y: $input-padding-y !default;
// $custom-select-padding-x: $input-padding-x !default;
// $custom-select-font-family: $input-font-family !default;
// $custom-select-font-size: $input-font-size !default;
// $custom-select-height: $input-height !default;
// $custom-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
// $custom-select-font-weight: $input-font-weight !default;
// $custom-select-line-height: $input-line-height !default;
// $custom-select-color: $input-color !default;
// $custom-select-disabled-color: $gray-600 !default;
// $custom-select-bg: $input-bg !default;
// $custom-select-disabled-bg: $gray-200 !default;
// $custom-select-bg-size: 8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color: $pwc-grey-5;
$custom-select-indicator: url("data:image/svg+xml,<svg xmlns='http:// www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#{$custom-select-indicator-color}' d='M2 4L0 1h4z'/></svg>");
// $custom-select-background: escape-svg($custom-select-indicator) no-repeat right $custom-select-padding-x center / $custom-select-bg-size !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

// $custom-select-feedback-icon-padding-right: add(1em * 0.75, (2 * $custom-select-padding-y * 0.75) + $custom-select-padding-x + $custom-select-indicator-padding) !default;
// $custom-select-feedback-icon-position: center right ($custom-select-padding-x + $custom-select-indicator-padding) !default;
// $custom-select-feedback-icon-size: $input-height-inner-half $input-height-inner-half !default;

// $custom-select-border-width: $input-border-width !default;
// $custom-select-border-color: $input-border-color !default;
// $custom-select-border-radius: $border-radius !default;
// $custom-select-box-shadow: inset 0 1px 2px rgba($black, 0.075) !default;

// $custom-select-focus-border-color: $input-focus-border-color !default;
// $custom-select-focus-width: $input-focus-width !default;
// $custom-select-focus-box-shadow: 0 0 0 $custom-select-focus-width $input-btn-focus-color !default;

// $custom-select-padding-y-sm: $input-padding-y-sm !default;
// $custom-select-padding-x-sm: $input-padding-x-sm !default;
// $custom-select-font-size-sm: $input-font-size-sm !default;
// $custom-select-height-sm: $input-height-sm !default;

// $custom-select-padding-y-lg: $input-padding-y-lg !default;
// $custom-select-padding-x-lg: $input-padding-x-lg !default;
// $custom-select-font-size-lg: $input-font-size-lg !default;
// $custom-select-height-lg: $input-height-lg !default;

// $custom-range-track-width: 100% !default;
// $custom-range-track-height: 0.5rem !default;
// $custom-range-track-cursor: pointer !default;
// $custom-range-track-bg: $gray-300 !default;
// $custom-range-track-border-radius: 1rem !default;
// $custom-range-track-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1) !default;

// $custom-range-thumb-width: 1rem !default;
// $custom-range-thumb-height: $custom-range-thumb-width !default;
// $custom-range-thumb-bg: $component-active-bg !default;
// $custom-range-thumb-border: 0 !default;
// $custom-range-thumb-border-radius: 1rem !default;
// $custom-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1) !default;
// $custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-focus-box-shadow !default;
// $custom-range-thumb-focus-box-shadow-width: $input-focus-width !default; // For focus box shadow issue in IE/Edge
// $custom-range-thumb-active-bg: lighten($component-active-bg, 35%) !default;
// $custom-range-thumb-disabled-bg: $gray-500 !default;

// $custom-file-height: $input-height !default;
// $custom-file-height-inner: $input-height-inner !default;
// $custom-file-focus-border-color: $input-focus-border-color !default;
// $custom-file-focus-box-shadow: $input-focus-box-shadow !default;
// $custom-file-disabled-bg: $input-disabled-bg !default;

// $custom-file-padding-y: $input-padding-y !default;
// $custom-file-padding-x: $input-padding-x !default;
// $custom-file-line-height: $input-line-height !default;
// $custom-file-font-family: $input-font-family !default;
// $custom-file-font-weight: $input-font-weight !default;
// $custom-file-color: $input-color !default;
// $custom-file-bg: $input-bg !default;
// $custom-file-border-width: $input-border-width !default;
// $custom-file-border-color: $input-border-color !default;
// $custom-file-border-radius: $input-border-radius !default;
// $custom-file-box-shadow: $input-box-shadow !default;
// $custom-file-button-color: $custom-file-color !default;
// $custom-file-button-bg: $input-group-addon-bg !default;
// $custom-file-text: (
//  en: "Browse"
// ) !default;
