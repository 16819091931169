:root {
  --gutter: #{$grid-gutter-width};
}

.row:not(.no-gutters) {
  margin-right: -$grid-gutter-width / 2;
  margin-right: calc(var(--gutter, #{$grid-gutter-width}) / -2);
  margin-left: -$grid-gutter-width / 2;
  margin-left: calc(var(--gutter, #{$grid-gutter-width}) / -2);

  > .col,
  > [class*=col-] {
    padding-right: $grid-gutter-width / 2;
    padding-right: calc(var(--gutter, #{$grid-gutter-width}) / 2);
    padding-left: $grid-gutter-width / 2;
    padding-left: calc(var(--gutter, #{$grid-gutter-width}) / 2);
  }
}
