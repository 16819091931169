// Variables
$debugLevel: 0; // set to 1 to enable breakpoints, set to 2 to additionally show outlines for all elements

// Breakpoints
@if ($debugLevel > 0) {
  body::after {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    display: block;
    padding: 0.5rem 1rem;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    background-color: cyan;
    background-color: rgba(0, 255, 255, 0.5);
    content: 'XXS';
    pointer-events: none;

    @include media-breakpoint-only(xs) {
      content: 'XS';
    }

    @include media-breakpoint-only(sm) {
      content: 'SM';
    }

    @include media-breakpoint-only(md) {
      content: 'MD';
    }

    @include media-breakpoint-only(lg) {
      content: 'LG';
    }

    @include media-breakpoint-only(xl) {
      content: 'XL';
    }

    @include media-breakpoint-only(xxl) {
      content: 'XXL';
    }
  }

  @if ($debugLevel > 1) {
    *,
    *::before,
    *::after {
      outline: 1px dashed rgba(0, 0, 255, 0.1) !important;
    }
  }
}

.container {
  & &,
  &-fluid &,
  & &-fluid,
  &-fluid &-fluid {
    font-size: 0;
    outline: auto;

    &::before {
      display: block;
      font-size: 1rem;
      background: red;
      content: 'Nested container detected';
    }

    > * {
      display: none;
    }
  }
}
