.dropdown {
  &.action-menu {
    .dropdown-toggle {
      display: flex;
      align-items: center;
      padding: p2r(10px) p2r(14px) p2r(11px);

      .icon {
        margin-left: p2r(12px);

        &.open {
          transform: rotate(180deg);
        }

        svg {
          width: p2r(16px);
          height: p2r(16px);
        }
      }

      &:hover .icon,
      .icon.open {
        svg path {
          fill: $white;
        }
      }

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      margin-top: p2r(4px);
      border: none;
      box-shadow: $g-box-shadow;

      .dropdown-item {
        display: flex;
        align-items: center;
        padding: p2r(12px) p2r(14px);

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: p2r(24px);
          margin-right: p2r(12px);
        }

        &.disabled {
          .icon svg path {
            fill: $dropdown-link-disabled-color;
          }
        }
      }
    }

    .dropdown-divider {
      margin-right: p2r(4px);
      margin-left: p2r(4px);
    }
  }
}
