.card {
  word-break: break-word;

  &--shadow {
    padding-top: $spacer-medium;
    padding-bottom: $spacer-medium;
    box-shadow: 0 p2r(2px) p2r(24px) p2r(5px) rgba($black, 0.04);
  }

  &--error {
    color: $pwc-status-red;
  }
}

.flex-flow {
  --flow-gap: #{p2r(10px)};

  display: flex;

  > * {
    margin: 0;
  }

  > * + * {
    margin-left: var(--flow-gap);
  }
}

.flex-flow--md {
  --flow-gap: #{p2r(24px)};
}

.flex-flow--lg {
  --flow-gap: #{p2r(30px)};
}

.flex-flow-v {
  display: flex;
  flex-flow: column;

  > * {
    margin: 0;
  }

  > * + * {
    margin-top: var(--flow-gap, #{p2r(10px)});
  }
}
