// stylelint-disable scss/dollar-variable-colon-space-after

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// $spacer: 1rem !default;
$spacers: (
  0: 0,
  // 1: ($spacer * 0.25),
  // 2: ($spacer * 0.5),
  // 3: ($spacer * 0.75),
  // 4: ($spacer * 1),
  // 5: ($spacer * 1.25),
  // 6: ($spacer * 1.5),
  // 7: ($spacer * 1.75),
  // 8: ($spacer * 2),
  // 9: ($spacer * 2.25),
  // 10: ($spacer * 2.5),
  // 11: ($spacer * 2.75),
  // 12: ($spacer * 3),
  // 13: ($spacer * 3.25),
  // 14: ($spacer * 3.5),
  // 15: ($spacer * 3.75),
  // 16: ($spacer * 4),
  small: p2r(14px),
  medium: p2r(28px),
  large: p2r(40px),
);
$spacer-small: map-get($spacers, small);
$spacer-medium: map-get($spacers, medium);
$spacer-large: map-get($spacers, large);

// This variable affects the `.h-*` and `.w-*` classes.
// $sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
// $sizes: map-merge(
//   (
//     25: 25%,
//     50: 50%,
//     75: 75%,
//     100: 100%,
//     auto: auto
//   ),
//   $sizes
// );
