@import "pwc-variables";

@mixin generic-shadow() {
  box-shadow: 0 0 30px -5px transparentize($light-silver, 0.8);
}

@mixin stretchy() {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}

// Dashboards
@mixin dashboard-common-styles() {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1rem;
    color: $seventy-black;
    font-weight: bold;
    letter-spacing: 1px;

    a {
      margin: 0;
      color: $rose;
    }
  }

  .ui.segment {
    // flex: 1;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    margin: 0;
    border: none;
    border-radius: 0;

    .item {
      margin-right: -1rem;
      margin-left: -1rem;
      padding: 1rem;
      padding-left: 2rem !important;
      font-size: $small;
      border-left: 3px solid transparent;
      outline: none;

      &:hover {
        border-left: 3px solid $rose;
      }

      .header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-bottom: 0 !important;

        .index {
          color: $rose;
          font-weight: bold;
          font-size: $regular;
          font-family: $g-font-family--secondary;
        }

        .tax {
          padding-left: 1.7rem;
          color: $ninety-black;
        }
      }

      .location {
        display: flex;
        align-items: center;
        padding-top: 0.5rem;
        padding-left: 2.5rem;

        img {
          height: $icon-regular;
          padding-right: 0.5rem;
        }
      }

      .date {
        padding-top: 0.5rem;
        padding-left: 2.5rem;
        color: $silver;
      }

      .narrow {
        padding-left: 0 !important;
      }
    }
  }
}

@mixin table-without-button() {
  tbody td:last-child {
    display: flex;
    justify-content: flex-start;
    padding: 1.25rem;
  }
}

@mixin page-header() {
  #header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .button-group {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .align-right {
        padding-left: 10rem;
      }

      p {
        margin: 1rem;
        color: $seventy-black;
        font-weight: bold;
      }
    }

    .title-group {
      display: flex;
      flex-direction: row;

      .black {
        color: $ninety-black;
      }
    }
  }
}

@mixin tax-sub-page() {
  #header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: p2r(16px);

    .button-group {
      display: flex;
      flex-direction: row;
    }

    .done {
      padding: 1rem;
      color: $rose !important;
      border: 1px solid $rose;
      box-shadow: 0 0;
    }

    .button,
    .btn {
      margin-left: 1rem;
    }
  }
}

@mixin filter-layout() {
  display: flex;

  .label {
    padding-bottom: 1rem;
    color: $rose;
    font-weight: bold;
  }
}
