@import "pwc-variables";

// base color
html,
body {
  background: $content-background;
}

// remove outline for inputs and buttons in safari/chrome
textarea,
input,
button,
select { outline: none; }

// scrollbars
::-webkit-scrollbar {
  z-index: 9990;
  width: 6px;
  height: 6px;
  background: white;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
}

h2 {
  height: fit-content;
  margin: 0;
  color: $ninety-black;
}

p {
  margin: 0;
  line-height: 1.7 !important;
}

hr {
  color: $silver;
  opacity: 0.3;
}

.padding-top-bottom {
  padding: 1rem 0 2rem 0;
}

.padding-top {
  padding-top: 2rem;
}

.padding-bottom {
  padding-bottom: 2rem;
}

.black {
  color: black;
}

.placeholder {
}

.cur-pointer {
  cursor: pointer;
}

.search--highlight {
  color: $white;
  background: $search-blue;
}

ins {
  background: #baffc0;
}

del {
  background: #ffc3c8;
}
