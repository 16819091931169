// stylelint-disable scss/dollar-variable-colon-space-after

// Color system

$white: $pwc-white;
// $gray-100: #f8f9fa !default;
// $gray-200: #e9ecef !default;
// $gray-300: #dee2e6 !default;
// $gray-400: #ced4da !default;
// $gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #474747 !default;
$gray-800: #343a40 !default;
// $gray-900: #212529 !default;
// $black: #000 !default;

// $grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
// $grays: map-merge(
//   (
//     "100": $gray-100,
//     "200": $gray-200,
//     "300": $gray-300,
//     "400": $gray-400,
//     "500": $gray-500,
//     "600": $gray-600,
//     "700": $gray-700,
//     "800": $gray-800,
//     "900": $gray-900
//   ),
//   $grays
// );

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
);

$primary: $pwc-rose;
$secondary: $pwc-grey-b;
$success: $pwc-status-green;
$info: $pwc-cyan;
$warning: $pwc-status-yellow;
$danger: $pwc-status-red;
$light: $pwc-grey-f;
$dark: $pwc-black-alt;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "orange": $pwc-orange,
  "tangerine": $pwc-tangerine,
  "yellow": $pwc-yellow,
  "grey": $pwc-grey,
  "light-grey": $pwc-light-grey,
  "dark-blue": $pwc-dark-blue,
  "teal": $pwc-teal,
);

// Set a specific jump point for requesting color jumps
// $theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
// $yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
// $yiq-text-dark: $gray-900 !default;
// $yiq-text-light: $white !default;
