// Fonts
$font-family-sans-serif: "Roboto", "Helvetica Neue", arial, sans-serif;
$font-family-georgia-serif: "Georgia", "Helvetica Neue", serif;
$font-family-base: $font-family-sans-serif;

// Content area
$content-background: #f1f1f3;

// Bright content area
$content-background-bright: #f6f6f6;

// Colors
$rose: #d93954; // #DB536A;
$white: white;
$silver: #6e6e6e; // changed due to web accessibility constraints; original value: #989899;
$light-silver: #bbb;
$burgundy: #a32020;
$ninety-black: #373737; // #404041;
$seventy-black: #6d6e71;
$green: #06b083;
$search-blue: #dc6900;// #5a88f0;

// Font sizes
$icon-small: 1rem;
$icon-regular: 1.5rem;
$icon-big: 2.2rem;

$small-5x: 0.3rem;
$small: 1rem;
$regular: 1.5rem;
$medium: 2rem;
$big: 2.5rem;

// Navbar
$navbar-background: #404042;

// Search
$search-area-height: 7rem;
