h1,
.h1 {
  @include text--heading();

  margin-bottom: p2r(12px);
  color: $pwc-dark-grey;
}

h2,
.h2 {
  @include text--heading-light();
}

h3,
.h3 {
  @include text--subheading();
}

h4,
.h4 {
  @include text--section-heading();

  margin-bottom: p2r(10px);
  color: $pwc-grey-3;
}

label {
  @include text--label();

  color: $pwc-grey-5;
}

p {
  margin: 0;
}

strong {
  &.medium {
    font-weight: 500;
  }
}

blockquote {
  &,
  .ql-editor & {
    margin-top: p2r(6px);
    margin-bottom: p2r(6px);
    padding-left: p2r(10px);
    border-left: p2r(4px) solid $pwc-grey-c;
  }
}
