// -----------------------------------------------------------------------------
// This file contains helper classes + other utilities
// -----------------------------------------------------------------------------

.cursor {
  &--default {
    cursor: default !important;
  }

  &--pointer {
    cursor: pointer !important;
  }

  &--auto {
    cursor: auto !important;
  }
}

.min-w-auto {
  min-width: auto !important;
}
