.btn {
  @each $color, $value in $theme-colors {
    &-#{$color} {
      $hover-value: mix($value, #303030, 70%);

      @include button-variant($value, $value, $hover-value, $hover-value, $hover-value, $hover-value);

      &.disabled,
      &:disabled {
        color: $pwc-grey-9;
        background-color: $pwc-grey-f;
        border-color: $pwc-grey-c;
      }
    }
  }

  &-outline-secondary {
    color: $pwc-black-alt;
    background-color: $pwc-white;
    border-color: $pwc-grey-d;

    @include hover-focus() {
      background-color: $pwc-white;
      border-color: $primary;
    }

    &.disabled,
    &:disabled {
      color: $pwc-grey-9;
      background-color: $pwc-grey-f;
      border-color: $pwc-grey-c;
    }

    &:not(:disabled):not(.disabled) {
      &:active,
      &.active {
        background-color: $pwc-white;
        border-color: $primary;
      }
    }
  }

  &-white {
    border-color: $pwc-rose;

    &:hover:not(:disabled):not(.disabled) {
      background-color: $pwc-grey-d;
      border-color: $pwc-rose;
    }
  }
}
