// Links

$g-link--color: $pwc-orange;
$g-link--hover-color: $pwc-orange-1;
$g-link--hover-decoration: underline;

// Typography

// stylelint-disable value-keyword-case
$g-font-family--system: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$g-font-family--primary: join('Helvetica Neue', $g-font-family--system);
$g-font-family--secondary: join(('Charter ITC', georgia, serif), $g-font-family--system);

$g-font-size-base: p2r(16px);
$g-line-height-base: (20 / 16);
$g-font-weight-base: 400;

// Forms

$g-input-color: $pwc-grey-5;
$g-input-disabled-color: $pwc-grey-a;
$g-input-placeholder-color: $pwc-grey;
$g-input-bg: $pwc-white;
$g-input-disabled-bg: $pwc-grey-f;
$g-input-border-color: $pwc-grey-8;
$g-input-disabled-border-color: $pwc-grey-b;
$g-input-focus-border-color: $pwc-rose;
$g-input-border-width: 1px;
$g-input-height: p2r(40px);
$g-input-padding-y: p2r(10px - $g-input-border-width);
$g-input-padding-x: p2r(16px - $g-input-border-width);
$g-input-box-shadow: none;
$g-input-focus-box-shadow: none;

$g-label-margin-bottom: p2r(8px);

// Other

$g-border-radius: 0;
$g-border-color: $pwc-grey-d;
$g-box-shadow: 0 p2r(6px) p2r(10px) rgba(0, 0, 0, 0.15);
