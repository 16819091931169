body {
  &.file-is-dragged {
    cursor: grabbing;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10000;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $font-size-lg;
      background-color: rgba($pwc-white, 0.5);
      content: 'Drop file(s) to attach it';
      pointer-events: none;
    }

    &[data-drop-hint]::before {
      content: attr(data-drop-hint);
    }
  }
}
