.table {
  .cw-1 {
    width: 100%;
  }

  .cw-2 {
    width: 200%;
  }

  .cell-wrap {
    white-space: normal !important;
  }
}
