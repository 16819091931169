// stylelint-disable scss/dollar-variable-colon-space-after

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// $btn-padding-y: $input-btn-padding-y !default;
// $btn-padding-x: $input-btn-padding-x !default;
// $btn-font-family: $input-btn-font-family !default;
// $btn-font-size: $input-btn-font-size !default;
// $btn-line-height: $input-btn-line-height !default;
// $btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping

// $btn-padding-y-sm: $input-btn-padding-y-sm !default;
// $btn-padding-x-sm: $input-btn-padding-x-sm !default;
// $btn-font-size-sm: $input-btn-font-size-sm !default;
// $btn-line-height-sm: $input-btn-line-height-sm !default;

// $btn-padding-y-lg: $input-btn-padding-y-lg !default;
// $btn-padding-x-lg: $input-btn-padding-x-lg !default;
// $btn-font-size-lg: $input-btn-font-size-lg !default;
// $btn-line-height-lg: $input-btn-line-height-lg !default;

// $btn-border-width: $input-btn-border-width !default;

$btn-font-weight: 700;
$btn-box-shadow: $g-input-box-shadow;
// $btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $g-input-focus-box-shadow;
$btn-disabled-opacity: 1;
// $btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125) !default;

// $btn-link-disabled-color: $gray-600 !default;

// $btn-block-spacing-y: 0.5rem !default;

// Allows for customizing button radius independently from global border radius
// $btn-border-radius: $border-radius !default;
// $btn-border-radius-lg: $border-radius-lg !default;
// $btn-border-radius-sm: $border-radius-sm !default;

// $btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;
