@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

quill-editor {
  position: relative;
  display: block;
  background-color: $white;
}

quill-editor:focus-within {
  .ql-toolbar {
    position: absolute;
    bottom: 100%;
    display: block;
    width: 100%;
    background: $white;
    border-color: $input-border-color;
  }

  .ql-container {
    padding-top: 1px;
  }
}

quill-editor:not(:focus-within) .ql-toolbar {
  display: none;
}

quill-editor:not(:focus-within) .ql-toolbar + .ql-container {
  border-color: $input-border-color;
  border-top: 1px solid $input-border-color !important;
}

quill-editor:focus-within {
  .ql-toolbar {
    border-bottom-color: $input-focus-border-color;
  }

  .ql-container {
    border-color: $input-focus-border-color;
  }
}

.ql-attachment {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: p2r(300px);
  padding: p2r(10px);
  background-color: $pwc-rose-light;
  border-radius: p2r(4px);
  cursor: pointer;

  &[data-attachment-id] &__uploading {
    display: none;
  }

  &[data-is-uploading] &__uploading-failed {
    display: none;
  }

  &__error,
  &__link-download {
    flex: 1 1 auto;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & &__link-download {
    color: $pwc-rose;
    font-weight: $font-weight-normal;
    cursor: pointer;

    &::before {
      display: inline;
      content: attr(data-filename);
    }
  }

  &:not([data-is-uploading]) &__spinner-uploading {
    display: none;
  }

  &__btn-remove {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $pwc-rose-light;
    border: none;
    cursor: pointer;

    .ql-disabled & {
      display: none;
    }

    svg {
      width: p2r(14px);
      height: p2r(14px);
    }
  }
}
