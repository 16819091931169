@import "src/styles/shared";
@import "src/styles/legacy/pwc-globals";

.accordion {
  .card {
    &:first-child {
      border-radius: p2r(3px) p2r(3px) 0 0;
    }

    &:last-child {
      border-radius: 0 0 p2r(3px) p2r(3px);
    }

    .card-header > button,
    .header-container .title {
      &.highlighted {
        color: $search-blue;

        &.active,
        &:hover {
          color: $pwc-rose;
        }
      }
    }

    .card-header > button {
      display: block;
      width: 100%;
      padding: 0;
      color: $gray-700;
      text-align: left;
      background: transparent;
      border: none;
    }

    .card-body {
      padding: p2r(14px);
      background-color: $pwc-grey-f;
      border-top: 1px solid $card-border-color;

      > button {
        margin-bottom: p2r(8px);
        color: $gray-700;
        font-weight: 500;
        font-size: p2r(13px);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .card-header {
      margin: 0;
      padding: 0;
      background-color: $white;
      border-bottom: 0;

      > .btn {
        &.active,
        &:hover {
          color: $pwc-rose;
        }

        &.changed::after {
          color: $green;
          font-weight: 900;
          font-size: 1.2rem;
          content: '*';
        }

        &:focus,
        &:hover,
        &:active,
        &:active:focus {
          text-decoration: none;
          outline: 0;
          box-shadow: none;
        }

        .icon.indicator {
          width: fit-content;
          margin-left: p2r(2px);
          transform: rotate(180deg);
        }

        &.collapsed {
          .icon.indicator {
            transform: rotate(0deg);
          }
        }
      }

      .title {
        margin-bottom: 2px;
        color: $gray-700;
        font-weight: 400;
        font-size: p2r(15px);
      }

      .sub-title {
        color: $pwc-grey;
        font-weight: 400;
        font-size: p2r(13px);
      }
    }

    .header-container {
      padding: p2r(10px) p2r(14px);
    }
  }

  &.tax-view {
    .card {
      margin-bottom: p2r(10px);
      border: 1px solid $pwc-grey-d;
      border-radius: p2r(3px);

      &.active {
        overflow: visible;
        box-shadow: $box-shadow;
      }
    }

    .card-body {
      background-color: $white;
    }

    .card-header {
      > .btn {
        &:hover {
          color: inherit;
        }
      }
    }
  }
}
