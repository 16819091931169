// stylelint-disable no-descending-specificity
$ng-select-disabled-text: $g-input-disabled-bg; // this is used for backgrounds

@import "~@ng-select/ng-select/scss/default.theme";

.ng-select {
  &.ng-select-multiple {
    .custom-control-label {
      font-weight: $font-weight-normal;
    }
  }

  &.ng-select-multiple,
  &.ng-select-single {
    .ng-select-container {
      height: $input-height;
      border-color: $input-border-color;
      border-radius: $input-border-radius;

      .ng-value-container {
        padding-top: 0;
        padding-bottom: p2r(1px);
        padding-left: $custom-select-padding-x;

        .ng-placeholder {
          top: initial;
          padding-top: 0;
          padding-bottom: 0;
          padding-left: 0;
          color: $input-placeholder-color;
        }

        .ng-input {
          padding-left: 0;
        }
      }
    }
  }

  &.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
    flex-wrap: nowrap;
    min-width: 0;
  }

  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $input-focus-box-shadow;
    } @else {
      box-shadow: none;
    }
  }

  &.ng-select-single .ng-clear-wrapper {
    display: none;
  }

  .ng-arrow-wrapper {
    padding-right: p2r(14px);
  }

  .ng-arrow-wrapper,
  .ng-arrow-wrapper:hover {
    .ng-arrow {
      top: 0;
      border-color: $custom-select-indicator-color transparent transparent;
      border-width: p2r(6px) p2r(4px) p2r(2px);
    }
  }

  &.ng-select-opened > .ng-select-container {
    .ng-arrow {
      top: p2r(-3px);
      border-color: transparent transparent $custom-select-indicator-color;
      border-width: p2r(2px) p2r(4px) p2r(6px);
    }
  }

  .ng-option .custom-control.custom-checkbox .custom-control-label {
    white-space: normal;
    pointer-events: none;
  }

  .ng-dropdown-panel.ng-select-bottom,
  .ng-dropdown-panel.ng-select-top {
    width: auto;
    min-width: 100%;
    border-radius: 0;

    .ng-dropdown-panel-items {
      .ng-option {
        margin-right: p2r(4px);
        margin-left: p2r(4px);
        padding: p2r(10px) p2r(12px);

        &:first-child {
          margin-top: p2r(4px);
        }

        &:last-child {
          margin-bottom: p2r(4px);
        }

        &:first-child,
        &:last-child {
          border-radius: 0;
        }

        &.ng-option-selected {
          background: $input-bg;
        }

        &.ng-option-marked {
          background: $pwc-rose-light;
          border-radius: p2r(3px);
        }

        &.ng-option-selected {
          &,
          &.ng-option-marked {
            .ng-option-label {
              font-weight: $font-weight-normal;
            }
          }
        }

        .ng-option-label {
          position: relative;
          display: flex;
          align-items: center;

          &::before,
          &::after {
            flex: 0 0 auto;
            color: $pwc-grey;
            border-radius: 100%;
          }

          &::before {
            width: p2r(16px);
            height: p2r(16px);
            margin-right: p2r(8px);
            background: $input-bg;
            border: p2r(1px) solid currentColor;
            content: '';
          }

          &::after {
            position: absolute;
            top: auto;
            left: p2r(4px);
            width: p2r(8px);
            height: p2r(8px);
            background: currentColor;
          }
        }

        &.ng-option-selected {
          .ng-option-label {
            &::after {
              content: '';
            }
          }

          .ng-option-label {
            &::before,
            &::after {
              color: $primary;
            }
          }
        }
      }
    }
  }

  &.ng-touched.ng-invalid {
    &::after {
      @include position--absolute();

      z-index: 1001; // to always show above ng-select border
      display: block;
      outline: 1px solid $pwc-red;
      outline-offset: -1px;
      content: '';
      pointer-events: none;
    }

    ~ .invalid-feedback {
      display: block;
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      border-color: $input-focus-border-color;
    }

    .ng-dropdown-panel {
      border: none;
      box-shadow: $g-box-shadow;

      &.ng-select-bottom {
        margin-top: p2r(1px);
      }

      &.ng-select-top {
        margin-bottom: p2r(1px);
      }
    }
  }

  &.ng-select-disabled {
    > .ng-select-container {
      background-color: $ng-select-disabled-text;
      border-color: $g-input-disabled-border-color;
    }

    .ng-arrow-wrapper {
      .ng-arrow {
        border-color: $pwc-grey-9 transparent transparent;
      }
    }
  }
}
