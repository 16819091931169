@keyframes global-error-blink {
  0% { background-color: black; }

  100% { background-color: rgba(255, 0, 0, 0.8); }
}

body.global-error::before {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: inline-block;
  padding: 10px;
  color: white;
  line-height: 15px;
  background-color: red;
  background-color: rgba(255, 0, 0, 0.8);
  content: 'Error: ' attr(data-globalError);
  font: {
    family: 'Helvetica Neue', helvetica, arial, sans-serif;
    size: 15px;
    weight: bold;
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: {
      direction: alternate;
      name: global-error-blink;
      duration: 1s;
      iteration-count: infinite;
      timing-function: ease-in-out;
    }
  }
}
