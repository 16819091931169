.btn {
  font-weight: 700;

  &-icon {
    display: flex;
    align-items: center;

    &:not(.btn-sm) {
      padding-right: p2r(20px - $btn-border-width);
      padding-left: p2r(20px - $btn-border-width);
    }

    &.btn-sm > *:only-child {
      margin-right: p2r(-2px);
      margin-left: p2r(-2px);
    }

    > * + * {
      margin-left: p2r(10px);
    }

    svg-icon,
    img,
    .fa,
    .fas,
    .far,
    .fal,
    .fad,
    .fab {
      display: flex;
      align-items: center;
      justify-content: center;
      width: p2r(16px);
      height: p2r(16px);
      font-size: p2r(12px);
    }

    svg-icon > svg {
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }
}

a[href],
.btn-link {
  font-weight: 700;
}
