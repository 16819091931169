// fixes for Semantic UI + Bootstrap mix

html {
  font-size: p2r(14px, 16px);
}

body {
  overflow-x: inherit;
}

.ui {
  &.input {
    input::-webkit-input-placeholder {
      color: $input-placeholder-color;
    }

    .input-group {
      flex-wrap: nowrap;

      input.form-control {
        width: auto;
      }
    }
  }

  &.table th,
  &.table td {
    vertical-align: inherit;
  }
}
