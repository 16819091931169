.table {
  margin: 0;

  th {
    @include text--table-header();

    padding: p2r(19px) p2r(5px);
    color: $pwc-grey-3;
  }

  th,
  td {
    &:first-child {
      padding-left: p2r(14px);
    }

    &:last-child {
      padding-right: p2r(14px);
    }
  }

  td {
    padding: p2r(12px) p2r(5px);
    color: $pwc-dark-grey;
  }
}

.table-bordered-parts {
  border: $table-border-width solid $table-border-color;

  thead {
    border-bottom: $table-border-width solid $table-border-color;

    th {
      border: none;
    }
  }

  tfoot {
    border-top: $table-border-width solid $table-border-color;
  }

  td {
    border: none;
  }
}
