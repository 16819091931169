svg-icon {
  display: inline-block;

  svg {
    display: block;

    &.icon {
      width: p2r(16px);
      height: p2r(16px);
    }
  }
}
