// -----------------------------------------------------------------------------
// This file contains styles for forms
// -----------------------------------------------------------------------------

.label-required::after {
  display: inline;
  content: ' *';
}

.show-feedback {
  ~ .invalid-feedback {
    display: block;
  }
}

.form-control,
.control-with-feedback {
  &.show-feedback.ng-invalid,
  &.ng-touched.ng-invalid {
    &:not(form):not(.only-error-feedback),
    + .input-group-append > * {
      &,
      .ui.input & {
        color: $primary;
      }

      outline: 1px solid $primary;
      outline-offset: -1px;
    }

    ~ * > .invalid-feedback,
    ~ .invalid-feedback {
      display: block;
    }
  }
}

.valid-feedback {
  display: block;
  color: $pwc-status-green;
}

select.form-control {
  &.ng-invalid {
    color: $input-placeholder-color;
  }

  option {
    color: $input-color;

    &[disabled] {
      display: none;
    }
  }
}
