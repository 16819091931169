.modal {
  &-fit {
    max-width: fit-content;

    .modal-content {
      margin: 0 p2r(25px);
    }
  }

  &-title {
    @include text--modal-title();
  }

  $modal-extra-padding: p2r(8px);

  &-header {
    padding-bottom: $modal-extra-padding;
  }

  &-body {
    @include text--modal-body();

    color: $pwc-grey-6;
  }

  $modal-line-padding-bottom: $modal-inner-padding + $modal-extra-padding - $modal-footer-margin-between * 0.5;

  &-footer {
    padding-top: $modal-extra-padding;

    &::before {
      display: block;
      flex: 0 0 calc(100% - #{$modal-footer-margin-between});
      height: p2r(1px);
      margin: 0 ($modal-footer-margin-between * 0.5) $modal-line-padding-bottom;
      background: $pwc-grey-d;
      content: '';
    }
  }
}
